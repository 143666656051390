import React, { useState } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Layout from "../../components/Layout"
import HeroHeader from "../../components/HeroHeader"
import HeroImage from "../../images/hospitality.jpg"
import SEO from "../../components/SEO"
import ApplicationQuote from "../../components/ApplicationQuotes"
import HospitalityImages from "../../components/applicationImageComponents/hospitalityImages"

const PageBody = styled.main`
  max-width: 1000px;
  margin: 0 auto;

  h1 {
    font-size: 2.5em;
  }
`

const Modal = styled.div`
  position: fixed;
  width: 900px;
  height: 600px;
  bottom: 50%;
  margin-bottom: -300px;
  left: 50%;
  margin-left: -450px;
  background-color: green;
  animation: fadeIn ease 0.5s;
  z-index: 3;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 910px) {
    width: 750px;
    height: 500px;
    margin-bottom: -250px;
    margin-left: -375px;
  }

  @media (max-width: 775px) {
    width: 600px;
    height: 400px;
    margin-bottom: -200px;
    margin-left: -300px;
  }

  @media (max-width: 625px) {
    width: 450px;
    height: 300px;
    margin-bottom: -150px;
    margin-left: -225px;
  }

  @media (max-width: 475px) {
    width: 350px;
    height: 233px;
    margin-bottom: -116.5px;
    margin-left: -175px;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  i {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 2;
    color: red;
    font-size: 2em;
    cursor: pointer;
  }

  button {
    background-color: transparent;
    padding: 0.5rem;
    border: 1px solid black;
    border-radius: 10px;
    cursor: pointer;
    transition-duration: 0.3s;

    &:hover {
      background-color: red;
      color: white;
    }
  }

  #machine-btn {
    position: absolute;
    bottom: 30px;
    left: 30px;
  }

  #application-btn {
    position: absolute;
    bottom: 30px;
    left: 145px;
  }
`

const StyledImageGalleryHeader = styled.h2`
  max-width: 1400px;
  margin: 1rem auto;
  border-bottom: 2px solid red;
`

const StyledImageGallery = styled.div`
  max-width: 1400px;
  display: flex;
  flex-flow: row wrap;
  margin: 1rem auto;
  justify-content: space-between;
`

const Thumbnail = styled.div`
  position: relative;
  max-width: 330px;
  max-height: 330px;
  overflow: hidden;
  cursor: pointer;
  margin: 10px 0;

  i {
    position: absolute;
    top: 10px;
    right: 20px;
    color: white;
    font-size: 1.4em;
    /* z-index: 3; */
    pointer-events: none;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;

    &:hover {
      color: red;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .overlay {
    display: none;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    bottom: 0;
    color: white;
    animation: slideIn ease 0.5s;
    pointer-events: none;

    @keyframes slideIn {
      0% {
        margin-bottom: -150px;
      }
      100% {
        margin-bottom: 0;
      }
    }

    h4 {
      margin-left: 15px;
    }

    a {
      text-decoration: none;
      color: white;
      pointer-events: auto;
      &:hover {
        color: red;
      }
    }
  }

  &:hover {
    .overlay {
      display: block;
    }
  }
`

const BackButton = styled.button`
  margin: 2rem;
  font-size: 1em;
  border: none;
  padding: 1rem;
  background-color: red;
  color: white;
  cursor: pointer;
`

const HospitalityPage = () => {
  const [modal, setModal] = useState({
    display: "none",
    name: "",
  })
  const [machineImage, setMachineImage] = useState({})
  const [machineLink, setMachineLink] = useState({})

  const [model, setModel] = useState({
    name: "",
  })

  function handleImageClick(e) {
    setModal({
      display: "block",
      name: e.target.dataset.model,
    })
    setMachineImage(e.target.src)
    setMachineLink(e.target.dataset.link)
    setModel({ name: e.target.dataset.model })
  }

  return (
    <>
      <SEO title="Hospitality" description="" />

      <Layout>
        <HeroHeader
          heroImage={HeroImage}
          // title="Warehouse Application"
          // subHeading="Keep Your Unique Warehouse Clean with Our Specialized Products."
          buttonTitle="See More"
          showButton="none"
        />
        <PageBody>
          <h1>Hospitality</h1>
          <p>
            Hospitality industry companies deal with food services,
            accommodations, recreation and even entertainment, and have a wide
            variety of surfaces to scrub or sweep. Our customers have a need to
            sweep large parking areas, vacuum carpeting around the guest
            sleeping areas, scrubbing the grease and spills in the large
            kitchens, and burnishing the floor finish in lobbies.
          </p>
          <h2>Get the Most for Your Money:</h2>
          <ul>
            <li>
              With a focus on maximizing the usage rate, our clients in this
              field often need to maximize the cleaning/hour, and with the most
              efficient staff levels.
            </li>
            <li>
              Many accounts now prefer the increased productivity of our rider
              scrubbers, and appreciate the compact size which allows transport
              inside the smaller elevators.
            </li>
          </ul>
          <ApplicationQuote quote="Lobby areas, pool decks, and other hard surface floors can be odor neutralized and cleaned with Zero3 aqueous ozone for a clean fresh scent and is much faster than mopping. -JC" />
          <ApplicationQuote quote="Why have we been mopping our restaurant floors?  It is just painting with grease.  Floors were sticky and the dining area smelled bad as a result.  A MicroMag/Recon Pad Assist is a durable work tool that allows staff to clean the dining room faster and far more effectively. -KB" />
          <ApplicationQuote quote="FactoryCat offers the best scrubbers and top-rated floor machines that can be used in our industry.  They excel in cleaning high gloss floors, tile and grout renovation, and even have capabilities to polish concrete. -JM" />
        </PageBody>

        <HospitalityImages />
        <Link to="/applications">
          <BackButton>All Applications</BackButton>
        </Link>
      </Layout>
    </>
  )
}

export default HospitalityPage

import React from "react"
import styled from "styled-components"

const StyledQuote = styled.div`
  padding: 0.1rem 0;
  max-width: 800px;
  margin: 0 auto;
  /* text-align: center; */
  span {
    color: red;
    padding: 0.3rem;
    font-style: italic;
    font-size: 1em;
    font-family: sans-serif;
    /* font-weight: bold;
    font-size: 2em; */
  }
  p {
    /* font-size: 1.2em;
    line-height: 40px; */
  }
`

const ApplicationQuote = props => {
  return (
    <StyledQuote>
      <p>
        <span>"</span>
        {props.quote}
        <span>"</span>
      </p>
    </StyledQuote>
  )
}

export default ApplicationQuote

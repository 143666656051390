import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const ImageContainer = styled.div`
  position: relative;
  width: 250px;
  height: 200px;
  margin: 6px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &:hover {
    .overlay {
      bottom: 0;
    }
  }
  i {
    position: absolute;
    top: 4px;
    right: 4px;
    color: white;
    cursor: pointer;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
    font-size: 1.4em;
    pointer-events: none;
  }
`

const OverLay = styled.div`
  position: absolute;
  width: 100%;
  color: white;
  bottom: -150px;
  /* display: none; */
  background-color: rgba(0, 0, 0, 0.5);
  padding: 1rem;
  transition-duration: 0.5s;
  /* pointer-events: none; */

  h4 {
    margin: 0;
  }
  span {
    font-weight: normal;
    color: white;
    text-decoration: none;
  }
`

const ImageComponent = props => {
  const [overlay, setOverlay] = useState("-40")
  return (
    <ImageContainer onClick={props.openModel}>
      <img src={props.imageSrc} alt={props.imageAlt} />
      <OverLay className="overlay" style={{ bottom: overlay }}>
        <h4>
          Model:{" "}
          <Link
            style={{ textDecoration: "none", color: "white" }}
            to={props.modelLink}
          >
            <span>{props.modelNo}</span>
          </Link>
        </h4>
        <h4>
          Application:{" "}
          <Link
            style={{ textDecoration: "none", color: "white" }}
            to={props.appLink}
          >
            <span>{props.application}</span>
          </Link>
        </h4>
      </OverLay>

      <i style={{ display: props.displayExpand }} className="fas fa-expand" />
    </ImageContainer>
  )
}

export default ImageComponent
